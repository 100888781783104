.container-login{
    //height: 100%;
    align-content: center;
    padding-bottom: 50px;
  
}

.card-login{
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important; 
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}

.card-sign-up{
    width: 600px;
    background-color: rgba(0,0,0,0.5) !important; 
    margin-top:100px;
    /* bring your own prefixes */
}

.card-header h3{
    color: white;
}

.logo-login{
    width: 100px;
}

.social_icon{
position: absolute;
right: 20px;
top: -45px;
}

@media screen and (min-width: 0px) {
    .card-login { width: 95%; }
  }
  @media screen and (min-width: 640px) {
    .card-login { width: 400px; }
  }
  @media screen and (min-width: 1024px) {
    .card-login { width: 400px; }
  }

@media screen and (min-width: 0px) {
    .card-sign-up { width: 95%; }
  }
  @media screen and (min-width: 640px) {
    .card-sign-up { width: 600px; }
  }
  @media screen and (min-width: 1024px) {
    .card-sign-up { width: 600px; }
  }
