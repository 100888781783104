.body {
  background-image: url("../images/Wallpaper.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

.alert-information{
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
}

.font-bold {
  font-family: "Cairo Bold";
}

.border-link {
  -webkit-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 17px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.container-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  margin-top: -20px;
  margin-left: -25px;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

//Estilo de clases principales de colores bootstrap
$theme-colors: (
  //"primary": red,
  //#2F75BA
);

$font-family-sans-serif: "Cairo Regular";

.border-bold {
  border: 5px solid white;
}

.img-profile {
  width: 200px;
  height: 200px;
  margin-top: -120px;
  border-radius: 100px;
  border: 5px solid white;
}

.img-profile-logo {
  width: 150px;
  height: 150px;
}

.img-sidebar {
  width: 70px;
  height: 70px;
}

.form-label {
  font-family: "Cairo Bold";
  letter-spacing: 1px;
}

.hr {
  border: 1px solid white;
}

.w-100 {
  width: 100%;
}

.button-transparent {
  background-color: transparent !important;
  color: red;
  background-repeat: no-repeat;
  border: 1px solid white;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.icon-eliminate {
  width: 30px;
}

.btn-no-style {
  background: none;
  border: 0;
  color: black;
  font: inherit;
  line-height: normal;
  -webkit-user-select: none; /* for button */
  //-webkit-appearance: button; /* for input */
  -moz-user-select: none;
  -ms-user-select: none;
}

.btn-no-style:hover {
  color: #006fb4;
  text-decoration: none;
}

.btn-no-style:focus {
  outline: none !important;
  box-shadow: none;
}

/*clase para colocar en un input lo que escriban en minúscula*/
.lowercase {
  text-transform: lowercase;
}

.banner-with-no-image{
  height: 250px;
  background-color: "#424242";
  width: 100%
}

.banner{
  height: 250px;
}

.pre-wrap{
  white-space: pre-wrap;
}

//Clase para pintar los svg de un color específico
//https://codepen.io/sosuke/pen/Pjoqqp
.filter-grey{
filter: invert(50%) sepia(4%) saturate(318%) hue-rotate(155deg) brightness(96%) contrast(88%);
}

@media screen and (min-width: 0px) {
  .banner { height: 200px; }
  .banner-with-no-image { height: 200px; }
}
@media screen and (min-width: 640px) {
  .banner { height: 250px; }
  .banner-with-no-image { height: 250px; }
}
@media screen and (min-width: 1024px) {
  .banner { height: 250px; }
  .banner-with-no-image { height: 250px; }
}

@supports(object-fit: cover){
  img.img-profile{
    object-fit: cover;
    object-position: center center;
  }
}

// Import Bootstrap and its default variables
@import "./node_modules/bootstrap/scss/bootstrap";
@import "./_fonts";
@import "./_login";
@import "./_sidenavigation";